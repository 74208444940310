import { lazy } from "react";
const Failurenotfound = lazy(() => import("../Container/Failurenotfound"));
const Failurepayment = lazy(() => import("../Container/Failurepayment"));
const Failure = lazy(() => import("../Container/Failure"));
const FailureProposal = lazy(() => import("../Container/FailureProposal"));
const ThankYou = lazy(() => import("../Container/ThankYou"));
const BasicDetails = lazy(() => import("../Container/basicDetails"));
const LastQuestion = lazy(() => import("../Container/lastQuestion"));
const PlanToChoose = lazy(() => import("../Container/planToChoose"));
const SelectedPlan = lazy(() => import("../Container/selectedPlan"));
const SubmitMobile = lazy(() => import("../Container/submitMobile"));
const QuickSummary = lazy(() => import("../Container/quickSummary"));
const PaymentSuccessOrFail = lazy(() =>
  import("../Container/paymentSuccessOrFail")
);
const Paymenthistory = lazy(() => import("../Container/paymenthistory"));
const AdvanceDetails = lazy(() => import("../Container/advanceDetails"));
const AdvanceDetailsQuote = lazy(() =>
  import("../Container/advanceDetailsQuote")
);
const Support = lazy(() => import("../Container/support"));
const ComparePlan = lazy(() => import("../Container/comparePlan"));
const Success = lazy(() => import("../Container/success"));
const PolicyHistory = lazy(() => import("../Container/policyHistory"));
const TermsAndConditions = lazy(() =>
  import("../Container/termsandconditions")
);
const Maintenance = lazy(() => import("../Container/maintenance"));

const Downloadpolicy = lazy(() => import("../Container/downloadPolicy"));
const routes = [
  {
    path: "/",
    exact: true,
    // name: 'Basic Details',
    // component: BasicDetails,
    name: "Maintenance",
    component: Maintenance,
  },
  {
    path: "/home",
    exact: true,
    name: "Home Page",
    component: BasicDetails,
  },
  {
    path: "/last-question",
    exact: true,
    // name: "last question Page",
    // component: LastQuestion,
    name: "Maintenance",
    component: Maintenance,
  },
  {
    path: "/choose-plan",
    exact: true,
    name: "plan choose Page",
    component: PlanToChoose,
  },
  {
    path: "/selected-plan",
    exact: true,
    name: "selected plan Page",
    component: SelectedPlan,
  },
  {
    path: "/submit-mobile",
    exact: true,
    name: "submit mobile Page",
    component: SubmitMobile,
  },
  {
    path: "/quick-summary",
    exact: true,
    name: "Quick Summary Page",
    component: QuickSummary,
  },
  {
    path: "/downloadpolicy/:quote_id",
    exact: true,
    name: "Download Policy",
    component: Downloadpolicy,
  },
  {
    path: "/payment-status",
    exact: true,
    name: "Payment Success Or Fail Page",
    component: PaymentSuccessOrFail,
  },
  {
    path: "/payment-history",
    exact: true,
    name: "Payment history Page",
    component: Paymenthistory,
  },
  {
    path: "/advance-details",
    exact: true,
    name: "Advance Details Page",
    component: AdvanceDetails,
  },
  {
    path: "/advance-details/:quote_id",
    exact: true,
    name: "Advance Details Page",
    component: AdvanceDetailsQuote,
  },
  {
    path: "/support",
    exact: true,
    name: "Support Page",
    component: Support,
  },
  {
    path: "/compare-plan",
    exact: true,
    name: "Compare Page",
    component: ComparePlan,
  },
  {
    path: "/success",
    exact: true,
    name: "Success Page",
    component: Success,
  },
  {
    path: "/failure",
    exact: true,
    name: "Failure Page",
    component: Failure,
  },
  {
    path: "/failure-proposal",
    exact: true,
    name: "FailureProposal Page",
    component: FailureProposal,
  },
  {
    path: "/failurenotfound",
    exact: true,
    name: "Not Found Page",
    component: Failurenotfound,
  },

  {
    path: "/failurepayment",
    exact: true,
    name: "Not Found Page",
    component: Failurepayment,
  },
  {
    path: "/thank-you",
    exact: true,
    name: "Thank You Page",
    component: ThankYou,
  },
  {
    path: "/policy-history",
    exact: true,
    name: "policy-history",
    component: PolicyHistory,
  },
  {
    path: "/terms",
    exact: true,
    name: "terms",
    component: TermsAndConditions,
  },
];

export default routes;
