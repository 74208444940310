import React, { memo } from 'react';
import TagManager from 'react-gtm-module'

import Routes from './routes';
import {gtm_key} from './utils/helper';

const tagManagerArgs = {
    gtmId: gtm_key
}

TagManager.initialize(tagManagerArgs)
const App = memo(() => <Routes />);

export default App;
