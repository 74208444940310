import React, { Suspense, memo } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Routes from './routes';

const Loader = () => {
    return (
        <div className="loader">
            <div className="loading">
                <div>
                    <div className="c1" />
                    <div className="c2" />
                    <div className="c3" />
                    <div className="c4" />
                </div>
            </div>
        </div>
    );
};

const Index = memo(() => {
    return (
        <Router>
            <Suspense fallback={<Loader />}>
                <Switch>
                    {Routes.map((itm, key) => (
                        <Route
                            key={key}
                            exact={itm.exact}
                            path={itm.path}
                            name={itm.name}
                            component={itm.component}
                        />
                    ))}
                </Switch>
            </Suspense>
        </Router>
    );
});

export default Index;
