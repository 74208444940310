import { useEffect, useRef } from "react";
import moment from "moment";

export const setQuoteIdLocalStorage = (itm) =>
  localStorage.setItem("quote_id", itm);

export const getQuoteIdLocalStorage = () => localStorage.getItem("quote_id");
export const setPreviousQuoteID = (itm) =>
  localStorage.setItem("previous_quote_id", itm);
export const getPreviousQuoteID = () =>
  localStorage.getItem("previous_quote_id");

export const setBasicDetails = (itm) =>
  localStorage.setItem("basic_details", itm);

export const getBasicDetails = () => localStorage.getItem("basic_details");

export const setLastQuestion = (itm) =>
  localStorage.setItem("last_questions", itm);

export const getLastQuestion = () => localStorage.getItem("last_questions");

export const setRestoration = (itm) => localStorage.setItem("restoration", itm);

export const getRestoration = () => localStorage.getItem("restoration");

export const setRecharge = (itm) => localStorage.setItem("recharge", itm);

export const getRecharge = () => localStorage.getItem("recharge");

export const setCopay = (itm) => localStorage.setItem("copay", itm);

export const getCopay = () => localStorage.getItem("copay");

export const setPlan = (itm) => localStorage.setItem("planname", itm);

export const getPlan = () => localStorage.getItem("planname");

export const setCoveramount = (itm) => localStorage.setItem("coveramount", itm);

export const getCoveramount = () => localStorage.getItem("coveramount");

export const setBonus = (itm) => localStorage.setItem("bonus", itm);

export const getBonus = () => localStorage.getItem("bonus");

export const setPremiumamount = (itm) =>
  localStorage.setItem("premium_amount", itm);

export const getPremiumamount = () => localStorage.getItem("premium_amount");

export const setHealth241 = (itm) => localStorage.setItem("health241", itm);

export const getHealth241 = () => localStorage.getItem("health241");

export const setCriticalillness = (itm) =>
  localStorage.setItem("criticalillness", itm);

export const getCriticalillness = () => localStorage.getItem("criticalillness");

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const currancyFormatter = (number) => {
  return parseFloat(number)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const acc_type = process.env.REACT_APP_TYPE;
export const gtm_key = process.env.REACT_APP_GTM_KEY;
// export const brochure_url = process.env.REACT_APP_BROCHURE_URL;
export const brochure_url = "https://payspot.edelweissinsurance.com/download/Edelweiss-Health-Brochure.pdf"

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const formatSI = (val) => {
  if (val.length === 6) {
    if (val[0] < 2) {
      return val[0] + " Lakh";
    } else {
      if (val[1] > 0) {
        return val / 100000 + " Lakhs";
      }
      return val[0] + " Lakhs";
    }
  } else if (val.length === 7) {
    return val.substring(0, 2) + " Lakhs";
  }
};

export const gtmEventFire = (vals) => {
  const GTM_Layer = window.dataLayer;
  GTM_Layer.push(vals);
  console.log(GTM_Layer);
};

export const calculateAge = (dateString) => {
  var today = new Date();
  var birthDate = moment(dateString, "DD-MM-YYYY").toDate();
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const calculateDays = (dateString) => {
  var today = new Date();
  var birthDate = moment(dateString, "DD-MM-YYYY").toDate();
  var Difference_In_Time = today.getTime() - birthDate.getTime();

  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  console.log(Difference_In_Days);
  return Difference_In_Days;
};

export const trackGAEvent = (event, category, action, label, value) => {
  window.ga("send", {
    hitType: event,
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value,
  });
};
