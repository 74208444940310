import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './assets/styles/style.scss';
import {getBasicDetails, getLastQuestion, setBasicDetails, setLastQuestion} from './utils/helper';

if (!JSON.parse(getBasicDetails())) {
    setBasicDetails(
        JSON.stringify({
            name: '',
            is_self: false,
            is_spouce: false,
            no_of_child: 0,
            dob: '',
            cover: ''
        })
    );
}

if (!JSON.parse(getLastQuestion())) {
    setLastQuestion(
        JSON.stringify([{
            type: '',
            name: [],
            other: '',
            isMedical: false,
            isOther: false
        }])
    );
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
